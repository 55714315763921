import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Layout, SEO } from '../components'

const Services = ({ data }) => (
  <Layout>
    <SEO title="Services" />

    <section role="main" className="container pt-5 pb-0 text-center">
      <h1 className="mt-md-5 mb-4">The views are our inspiration.</h1>
      <p className="lead mb-5">
        We believe visual is a great way to explain complex ideas.
      </p>
    </section>

    <section className="container-fluid pb-5 px-0">
      <Img
        className="img-fluid w-100 h-100"
        fluid={data.banner.childImageSharp.fluid}
        alt="Construction time-lapse monitoring system"
      />
    </section>

    <section className="container pb-5">
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col mb-4">
          <div className="card shadow h-100">
            {/* <Img
              className="card-img-top img-fluid"
              fluid={data.timelapse.childImageSharp.fluid}
              alt="Construction time-lapse monitoring system"
              imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
            /> */}
            <div className="card-body">
              <i className="material-icons md-36 mb-3">linked_camera</i>
              <h4>Construction time-lapse monitoring system</h4>
              <p className="text-muted">Capture the full construction cycle beginning to end.</p>
              <p>With construction efficiency increasing, so does works on site rapidly progress. Monitoring of the works is suddenly critical, which is why time-lapse photography has become an indispensable tool to projects worldwide. Capture progress on your site 24/7 and track all images online via a dedicated project website. At the end of each month, images will compiled as a high-quality site progress video report.</p>

              <p className="mb-0">Our system includes:</p>
              <ul className="mb-0">
                <li>24MP photograps + 4K video output</li>
                <li>Wireless 4G camera connectivity on site</li>
                <li>Solar powered for sustainability</li>
                <li>Dedicated online project website</li>
                <li>Robust website with download/export capabilities</li>
                <li>International editing standard (stringent filtering)</li>
                <li>Full-system maintenance throughout construction cycle</li>
              </ul>
            </div>
          </div> 
        </div>

        <div className="col mb-4">
          <div className="card shadow h-100">
            <div className="card-body">
              <i className="material-icons md-36 mb-3">videocam</i>
              <h4>Site progress video reporting</h4>
              <p className="text-muted">The new way to report summarised works carried out at the site.</p>
              <p>With our team of videographer, photographer and drone pilot, TIMELAPSE captures the latest site progress & project milestones every month. We cover areas such as construction zones and built layouts, construction activities as well as aerial views of the site. All images and footage are professional compiled and edited to creates a 2-3 minute highlights that showcases the past month's progress.</p>
              <ul className="mb-0">
                <li>Monthly site progress video report to stakeholders</li>
                <li>CIDB-certified team with essential construction knowledge deployed to site</li>
                <li>4K ground + aerial footage</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col mb-4 mb-md-0">
          <div className="card shadow h-100">
            <div className="card-body">
              <i className="material-icons md-36 mb-3">live_tv</i>
              <h4>Project documentary & corporate video</h4>
              <p className="text-muted">Scripting & presenting the construction chronology as a broadcast-quality documentary.</p>
              <p>Recreate the life of a project through our broadcast-quality video documentary. From inception to completion, we capture and narrate the construction chronology, using extensive footage taken via time lapse and site progress videos on top of specific scripted and filmed scenes to enhance production value.</p>

              <p className="mb-0">Through storyboards, scripts, voice-overs, interviews with key personnel plus other production tools, the project documentary becomes an essential medium that, more than just video, becomes a reliable and marketable asset for corporate use, branding, promotions, project referencing, training and more.</p>
            </div>
          </div>
        </div>

        <div className="col mb-0">
          <div className="card shadow h-100">
            <div className="card-body">
              <i className="material-icons md-36 mb-3">timelapse</i>
              <h4>360 time builder</h4>
              <p className="text-muted">360 degrees image platform of internal works from start to completion.</p>
              <p className="mb-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid bg-dark text-light">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-12 col-md">
            <i className="material-icons md-36 mb-2">camera_enhance</i>
            <h3 className="font-weight-bold mb-1">
              We'll capture your insights
            </h3>
            <p className="lead mb-5 mb-md-0">
              Get in touch and let's discuss further.
            </p>
          </div>
          <div className="col-12 col-md-auto">
            <a href="/contact/" className="btn btn-lg btn-primary">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Services

export const query = graphql`
  query {
    banner: file(relativePath: {eq: "service-banner.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    timelapse: file(relativePath: {eq: "service-timelapse.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`